import { RootState } from '@/store/store'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PaymentModal from '../../../Payment/Components/PaymentModal'
import { selectPlan } from '../../../Plan/PlanApiSlice/PlanSlice'
import { Plan } from '../../PlanApiSlice/Representations/Plan'
import getPlans from '../../utils/PlanService'
import PlanCard from '../PlanCard'
import PlansList from '../PlanList'
import PlanModal from '../PlanModal'

export default function PlanPage() {
  const [plans, setPlans] = useState<Plan[]>([])
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fakePlans, setFakePlans] = useState<Plan[]>([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isAuthenticated } = useSelector((state: RootState) => state.AuthSlice)
  const { selectedPlan, showPlanModal, showPaymentModal } = useSelector((state: RootState) => state.PlanSlice)

  useEffect(() => {
    if (error) {
      const skeletonPlans: Plan[] = Array(3).fill({
        id: 'skeleton',
        name: 'Cargando...',
        image: '',
        monthlyPrice: 0,
        annualPrice: 0,
        features: [],
      })
      setFakePlans(skeletonPlans)
    }
  }, [error])

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = await getPlans()
        setPlans(data)
      } catch (error: any) {
        setError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchPlans()
  }, [])

  useEffect(() => {
    if (selectedPlan && isAuthenticated) {
      dispatch(selectPlan(selectedPlan))
    }
  }, [selectedPlan, isAuthenticated, dispatch])

  const handleSelectPlan = (plan: Plan) => {
    if (!isAuthenticated) {
      dispatch(selectPlan(plan))

      navigate('/login')
    } else {
      dispatch(selectPlan(plan))
    }
  }

  return (
    <Container>
      {isLoading ? (
        <p>Cargando Planes...</p>
      ) : error ? (
        fakePlans.map((plan, index) => (
          <PlanCard key={index} plan={plan} isSelected={false} onSelectPlan={() => {}} />
        ))
      ) : (
        <PlansList plans={plans} selectedPlanId={selectedPlan?.id ?? null} onSelectPlan={handleSelectPlan} />
      )}
      {showPlanModal && <PlanModal />}
      {showPaymentModal && <PaymentModal />}
    </Container>
  );
}  