import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function PetProfile() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const petId = searchParams.get('id')

  useEffect(() => {
    if (!petId) {
      alert('No se encontró un ID válido.')
      navigate('/')
      return
    }

    const appUrl = `petclub-app://petscreen/${encodeURIComponent(petId)}`

    try {
      window.location.href = appUrl

      const timeout = setTimeout(() => {
        navigate('/')
      }, 2000)

      return () => clearTimeout(timeout)
    } catch (error) {
      console.error('Error al redirigir:', error)
      navigate('/')
    }
  }, [petId, navigate])

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Perfil de Mascota</h1>

      <div style={{ marginTop: '20px' }}>
        <a
          href="https://play.google.com/store/apps/details?id=com.petclub.app"
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            textDecoration: 'none',
            borderRadius: '5px',
          }}
        >
          Descargar la App
        </a>
      </div>
    </div>
  )
}
