import { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import './App.css'
import { initializeAuth } from './Auth/AuthApiSlice/AuthSlice'
import Footer from './Common/Components/Footer'
import Header from './Common/Components/Header'
import AppRoutes from './Routes'
import ScrollToTop from './ScrollToTopRoute'
import store, { AppDispatch } from './store/store'

function AppContent() {
  const dispatch: AppDispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(initializeAuth())
  }, [dispatch])

  const isHiddenHeaderFooterRoute = location.pathname === '/paymentservice' || location.pathname === '/paymentsuccess' || location.pathname === '/paymentSuccess' || location.pathname === '/paymentservicetest' || location.pathname === '/paymentsuccesstest' 

  return (
    <div className="app">
      <ScrollToTop />
      {!isHiddenHeaderFooterRoute && <Header />}
      <AppRoutes />
      {!isHiddenHeaderFooterRoute && <Footer />}
    </div>
  )
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  )
}

export default App
