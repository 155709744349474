import { useEffect } from 'react'

export default function PaymentService() {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'module'
    script.src = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.js'
    document.body.appendChild(script)

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdn.payphonetodoesposible.com/box/v1.1/payphone-payment-box.css'
    document.head.appendChild(link)
    const tokenPayphone = process.env.REACT_APP_TOKEN_PAYPHONE

    script.onload = () => {
      const ppb = new (window as any).PPaymentButtonBox({
        token: tokenPayphone,
        amount: 100,
        amountWithoutTax: 100,
        amountWithTax: 0,
        tax: 0,
        service: 0,
        tip: 0,
        reference: 'Validación Tarjeta Activa',
        clientTransactionId: 'VAL_' + Date.now(),
      })
      ppb.render('pp-button')
    }

    return () => {
      document.body.removeChild(script)
      document.head.removeChild(link)
    }
  }, [])

  return (
    <div>
      <div id="pp-button"></div>
    </div>
  )
}
