import { Slice, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REFRESH_TOKEN, TOKEN } from '../../config/constants';
import { getDeviceId } from '../../utils/DeviceId';
import { getSecuredItem, setSecuredItem } from '../../utils/Storage';
import AuthUser from '../Representations/AuthUser';
import { clearTokensAndLogout, fetchUserWithAccessToken, refreshAccessToken } from '../Utils/AuthUtils';
import { loginActions } from './loginActions';

export interface AuthState {
  authUser: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

export const initialState: AuthState = {
  authUser: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

export const initializeAuth = createAsyncThunk('auth/initialize', async (_, { rejectWithValue }) => {
  const refreshToken = await getSecuredItem(REFRESH_TOKEN);
  const accessToken = await getSecuredItem(TOKEN);

  try {
    const deviceId = await getDeviceId();

    if (accessToken) {
      try {
        const user = await fetchUserWithAccessToken(accessToken);
        return { user, isAuthenticated: true };
      } catch (error) {
        if (refreshToken) {
          try {
            const newTokenData = await refreshAccessToken(refreshToken, deviceId);
            await setSecuredItem(TOKEN, newTokenData.AccessToken);
            await setSecuredItem(REFRESH_TOKEN, newTokenData.RefreshToken);
            const newUser = await fetchUserWithAccessToken(newTokenData.AccessToken);
            return { user: newUser, isAuthenticated: true };
          } catch {
            await clearTokensAndLogout();
            return { user: null, isAuthenticated: false };
          }
        } else {
          await clearTokensAndLogout();
          return { user: null, isAuthenticated: false };
        }
      }
    } else if (refreshToken) {
      try {
        const newTokenData = await refreshAccessToken(refreshToken, deviceId);
        await setSecuredItem(TOKEN, newTokenData.AccessToken);
        await setSecuredItem(REFRESH_TOKEN, newTokenData.RefreshToken);
        const newUser = await fetchUserWithAccessToken(newTokenData.AccessToken);
        return { user: newUser, isAuthenticated: true };
      } catch {
        await clearTokensAndLogout();
        return rejectWithValue('Tokens expirados. Por favor, inicia sesión de nuevo.');
      }
    } else {
      await clearTokensAndLogout();
      return { user: null, isAuthenticated: false };
    }
  } catch (error) {
    console.error('Error inicializando autenticación:', error);
    return rejectWithValue('Error inicializando autenticación');
  }
});

const authSlice: Slice<AuthState> = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state: AuthState) => {
      state.authUser = null;
      state.isAuthenticated = false;
      clearTokensAndLogout();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeAuth.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(initializeAuth.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.authUser = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated;
      } else {
        state.authUser = null;
        state.isAuthenticated = false;
      }
      state.error = null;
    });
    builder.addCase(initializeAuth.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isAuthenticated = false;
      state.authUser = null;
    });
    builder.addCase(loginActions().login.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(loginActions().login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.authUser = action.payload.user;
      state.error = null;
    });
    builder.addCase(loginActions().login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isAuthenticated = false;
      state.authUser = null;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
